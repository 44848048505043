@import "shared/styles/media";

.show_more {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .reviews_list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .product_review {
      width: 100%;
      max-width: 500px;
    }
  }

  @include md {
    display: none;
  }

  .more_button {
    display: flex;
    padding: 14px 25px !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    align-self: stretch;

    color: var(--UIMain-Black, #000) !important;

    /* p16-med */
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 140%; /* 22.4px */

    border-radius: 10px;
    background: var(--UIMain-Gray-Background, #f2f2f2) !important;

    &:hover {
      border-color: var(--UIMain-Gray, #f2f2f2) !important;
    }

    .icon {
      width: 22px;
      height: 22px;
    }
  }
}
