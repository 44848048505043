@import "../../../../../shared/styles/typography";

.faq {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  margin-bottom: 90px;

  .title {
    @extend %header_1_txt;
  }

  .list {
    display: flex;
    flex-direction: column;

    .item {
      &:last-child {
        border-bottom: 1px solid var(--stroke-color);
      }
    }
  }
}
