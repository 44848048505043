.order_product {
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 16px;
  font-family: var(--font);
  color: var(--text-color);
}

.image {
  .inner {
    width: 150px;
    height: auto;
    //padding-bottom: 64%;
    position: relative;

    img {
      width: 100%;
      height: auto;
      //position: absolute;
      //top: 0;
      //left: 0;
      //width: 100%;
      //height: 100%;
      object-fit: contain;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;

  font-size: 14px;

  .name {
    flex: 1;
  }
}
