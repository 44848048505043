@import "shared/styles/typography.scss";

.wishlist_guest_empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  margin: 0 auto;
  margin-bottom: 100px;
  max-width: 400px;
}

.image {
  color: var(--primary);
}

.title {
  @extend %header_2_txt;
  text-align: center;
}

.description {
  text-align: center;
}

.button {
  margin-top: 12px;
}
