@import "shared/styles/media.scss";
@import "shared/styles/typography.scss";

.modal_body {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  .input_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      @extend %paragraph_16_med;
    }
  }

  .description {
    @extend %paragraph_16_reg;
  }

  .send_button {
    display: flex;
    gap: 5px;
    align-items: center;
    max-width: 214px;
    width: 100%;
    @include sm {
      max-width: none;
    }
    svg {
      width: 22px;
      height: 22px;
    }
  }
}
