@import "shared/styles/media.scss";

.product_review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    gap: 5px;

    .avatar {
      display: flex;
      padding: 8px 10px 7px 10px;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      flex-shrink: 0;

      border-radius: 9999px;
      background: var(--UIMain-Outline-2, #c5c5c5);

      color: var(--UIMain-Gray-Background, #f2f2f2);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
    }

    .score_info {
      display: flex;
      flex-direction: column;
      gap: 1px;
      width: calc(100% - 32px - 5px);

      .info {
        color: var(--UIMain-Gray, #8b8b8b);

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 15.6px */
      }

      .stars {
        color: hsla(33, 100%, 61%, 1);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1px;
        flex-shrink: 0;
        width: 100%;
        .star {
          flex-shrink: 0;
          display: flex;
          align-items: center;

          svg {
            width: 14px;
            height: 14px;
          }
        }

        .product_name {
          color: var(--UIMain-Gray, #8b8b8b);
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 16.8px */
          margin-left: 4px;
          display: flex;

          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .galleries {
    display: flex;
    gap: 10px;
    flex-direction: row;
    overflow: auto;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @include md {
      gap: 10px;
    }
  }

  .text {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    word-wrap: break-word;

    word-break: break-word;

    strong {
      font-weight: 700;
    }
  }
  .answerInline {
    display: none;
    @include md {
      display: block;
    }
  }
}


.answer {
  max-width: 500px;
  width: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  background: #F5F5F5;
  font-family: var(--font);
  .answerHeader {
    display: flex;
    gap: 5px;
    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;
    }
    .textBlock {
      display: flex;
      flex-direction: column;
      gap: 1px;
      h3 {
        color: #000;
        font-size: 14px;
        font-weight: 700;
        line-height: 120%;
      }
      h4 {
        color: #8B8B8B;
        font-size: 12px;
        font-weight: 500;
        line-height: 130%;
      }
    }
  }
  .answerBody {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
  @include md {
    display: none;
  }
}

.answerAlwaysHide {
  display: none !important;
}

.answerAlwaysShow {
  display: flex !important;
}