.marker {
  width: 48px;
  height: 79px;
  transform: translate(-50%, calc(-100% + 16px));

  svg {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }
}
