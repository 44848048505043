.datePicker {
  span {
    border-color: #b0b0b0;
    color: #000000 !important;
  }
  input {
    padding: 15px !important;
    height: 48px !important;
  }
  button {
    margin-top: 6px !important;
    width: 48px !important;
    height: 48px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}