.controls {
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: flex-end;
  margin-left: -12px;
  margin-bottom: -12px;
  padding: 0;
  color: #000;
  width: 100%;

  .navigation_button {
    background: #ffffff;
    color: #000;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.2);
    border-radius: 999px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    margin-right: 12px;
    position: absolute;
    bottom: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    &:active:not(:disabled) {
      transition: all 0.3s linear;
      transform: scale(0.95);
    }

    &.topper {
      bottom: 310px;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    .loader {
      width: 22px;
      height: 22px;
      animation: rotation 1s linear infinite;

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
